<template>
  <div>
    <van-loading class="await" color="#10955b" size="50px" vertical
      >授权中...</van-loading
    >
  </div>
</template>

<script>
import { login } from '@/api/login'

import { setItem, getItem, removeItem } from '@/utils/auth'

export default {
  name: '',
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {
    this.getLongin()
  },
  mounted() {},
  methods: {
    getLongin() {
      let self = this
      login({
        ticket: this.$route.query.ticket
      }).then((res) => {
        console.log(res)
        if (res.code == 200) {
          setItem('Admin-Token', res.result.token)

          this.$store.dispatch('user/getUserInfo')
        } else if (res.code == 500) {
          console.log('授权失败')
          this.$toast.fail(res.message)
          removeItem('Admin-Token')
          setTimeout(() => {
            undefined

            self.$toast.clear()
            ZWJSBridge.onReady(() => {
              ZWJSBridge.close() //这个是浙里办内部的api，调用close()接口关闭通过openLink打开的页面
                .then((result) => {
                  console.log(result)
                })
                .catch((error) => {
                  console.log(error)
                })
            })

            window.history.go(-1)
          }, 2000)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.await {
  margin-top: 50%;
}
</style>
